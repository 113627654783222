// 3rd party
import React, {useEffect, useContext} from "react"
import {graphql as gql} from "gatsby"
import queryString from "query-string"

// components
import Container from "../components/styled/container"
import Seo from "../components/general/seo"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import ArchiveList from "../components/archive/archiveList"
import MainMediumArchive from "../components/archive/mainMediumArchive"
import {GlobalDispatchContext, GlobalStateContext} from "../context/GlobalContextProvider"
import MediumArchive from "../components/archive/mediumArchive"
import Scrollable from "../components/general/scrollable"

export default function Medium({data, location}) {
  let projectId = queryString.parse(location.search).p
  let {medium, childWorks, parentWorks} = data
  let works = [...childWorks.edges.concat(parentWorks.edges)]

  const appDispatch = useContext(GlobalDispatchContext)
  const appState = useContext(GlobalStateContext)
  let filteredProject = appState.navData.project.filter(({node: project}) => project.id.toString() === projectId)
  filteredProject = !!filteredProject.length && filteredProject[0].node

  useEffect(() => {
    let type = "medium"
    let page = {
      main: {medium: medium.parent?.node.id || medium.id, project: 0},
      sub: {medium: medium.id, project: 0},
      currentId: 0,
      template: "medium"
      //type: type
    }
    appDispatch({type: "setPage", value: {type: type, page: page}})
    appDispatch({type: "setAttached", value: []})
  }, [appDispatch, medium])

  return (
    <>
      <Seo
        title={(medium.parent ? medium.parent.node.title + " - " : "") + medium.title}
        description={medium.description.content}
      />
      <Container>
        <Breadcrumbs postType="medium" medium={medium} child={filteredProject} />
        <Scrollable>
          <ArchiveList>
            {!!parentWorks.edges.length ? (
              <MainMediumArchive {...{works, medium}} />
            ) : (
              <MediumArchive {...{works, projectId}} mediumId={medium.id} /*filter={router.query.project}*/ />
            )}
          </ArchiveList>
        </Scrollable>
      </Container>
    </>
  )
}

export const QUERY_MEDIUM_BY_SLUG = gql`
  query MediumQuery($id: Int!) {
    parentWorks: allWpWork(filter: {mediumTypes: {nodes: {elemMatch: {wpParent: {node: {databaseId: {eq: $id}}}}}}}) {
      edges {
        node {
          ...attachedWork
        }
      }
    }
    childWorks: allWpWork(filter: {mediumTypes: {nodes: {elemMatch: {databaseId: {eq: $id}}}}}) {
      edges {
        node {
          ...attachedWork
        }
      }
    }
    medium: wpMediumType(databaseId: {eq: $id}) {
      ...mediumFieldsWithDescription
      children: wpChildren {
        nodes {
          ...mediumFieldsWithDescription
        }
      }
      parent: wpParent {
        node {
          ...mediumFields
        }
      }
    }
  }
`
